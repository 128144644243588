// 
// _card.scss
// 

.card {
  margin-bottom: 16px;
  background-color: $card-bg;
  border: 1px solid $border;
  box-shadow: $card-shadow;
  .card-header{
    background-color: $card-bg;
    border-bottom: 1px solid $border;
    &:first-child {
      border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0;
    }
  }
  .card-footer{
    background-color: $card-bg;
    border-top: 1px dashed $border;
  }
}
.card-title {
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: $gray-700;
}

pre{
  margin-bottom: 0 !important;
  &.prettyprint{
    border: none !important;
  }
}


/* SPAN elements with the classes below are added by prettyprint. */
.pln { color: #9c9ebb }  /* plain text */

@media screen {
  .str { color: #2b81ff !important}  /* string content */
  .kwd { color: #008 !important}  /* a keyword */
  .com { color: #066 !important}  /* a comment */
  .typ { color: #f93636 !important}  /* a type name */
  .lit { color: #066 !important}  /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  .pun, .opn, .clo { color: #660 }
  .tag { color: #00bcd4 !important }  /* a markup tag name */
  .atn { color: #f93636 !important; }  /* a markup attribute name */
  .atv { color: #2b81ff !important;}  /* a markup attribute value */
  .dec, .var { color: #f93636 !important;}  /* a declaration; a variable name */
  .fun { color: red }  /* a function name */
}

/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str { color: #060 }
  .kwd { color: #00bcd4; font-weight: bold }
  .com { color: #600; font-style: italic }
  .typ { color: #404; font-weight: bold }
  .lit { color: #044 }
  .pun, .opn, .clo { color: #440 }
  .tag { color: #00bcd4; font-weight: bold }
  .atn { color: #404 }
  .atv { color: #060 }
}

/* Put a border around prettyprinted code snippets. */
pre.prettyprint { padding: 2px; border: 1px solid transparent !important }

/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0 } /* IE indents via margin-left */
li.L0,
li.L1,
li.L2,
li.L3,
li.L5,
li.L6,
li.L7,
li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,
li.L3,
li.L5,
li.L7,
li.L9 { background: #eee }